.faq-sec {
  padding: 85px 0;
  background-color: var(--body-secondary-bg);
}
.faq-sec .faq-text{color: var(--theme-primary);font-size: 40px;line-height: 1;font-weight: 300;}
.faq-sec .faq-text .question {
  font-size: 70px;
  line-height: 76px;
}
.faq-accordion .accordion-header button[aria-expanded="false"] {
  border-bottom: 1px solid #e5e5e5;
}
.faq-accordion .accordion-header button {
  color: var(--text-primary-color);
  font-size: var(--font-size-regular);
  line-height: 26px;
  font-weight: 600;
  background-color: transparent;
  padding: 0 0 25px 16px;
  margin: 0 0 25px;
  box-shadow: none;
  border-bottom: 1px solid transparent;
}
.faq-accordion .accordion-header button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  border-left: 2px solid #d5d5d5;
}
.faq-accordion .accordion-header button[aria-expanded="true"]::before {
  border-left: 2px solid var(--theme-primary);
}
.faq-accordion .accordion-body {
  color: var(--text-secondary-color);
  padding: 22px 30px;
  margin: 0 0 40px 16px;
}
.faq-accordion .accordion-collapse {
  border-left: 2px solid var(--theme-primary);
}
.faq-accordion .accordion-item:last-of-type .accordion-collapse{
  border-radius: 0;
}
.faq-form {
  padding: 35px 22px;
  background-color: var(--theme-secondary);
  border-radius: 8px;
}
.faq-form .input-group input,
.faq-form .input-group textarea {
  padding: 0 0 20px;
  margin: 0 0 20px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #f6f6f6;
}
.faq-form .input-group input::placeholder,
.faq-form .input-group textarea::placeholder {
  color: #e2e2e2;
}
.faq-form .input-group input:focus,
.faq-form .input-group textarea:focus {
  box-shadow: none;
}
.faq-accordion-content {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 991px) {
  .faq-sec {
    padding: 60px 0;
  }
  .faq-sec .faq-text {
    font-size: 30px;
  }
  .faq-sec .faq-text .question {
    font-size: 55px;
    line-height: 60px;
  }
}
@media (max-width: 575px) {
  .faq-sec .faq-text {
    font-size: 25px;
  }
  .faq-sec .faq-text .question {
    font-size: 45px;
    line-height: 60px;
  }
  .faq-accordion .accordion-header button {
    padding: 0 0 15px 12px;
    margin: 0 0 20px;
    font-size: 14px;
    line-height: 20px;
  }
  .faq-accordion .accordion-body {
    padding: 15px 15px;
    margin: 0 0 30px 12px;
    font-size: 14px;
    line-height: 20px;
  }
  .faq-form {
    padding: 25px 15px;
  }
  .faq-form .input-group input,
  .faq-form .input-group textarea {
    padding: 0 0 15px;
    margin: 0 0 15px;
  }
}
