.section-title { font-size: 24px; font-weight: bold; margin-bottom: 0px; color: light-dark(var(--text-primary-color), #fff); }
.share-title { font-size: 16px; font-weight: normal; margin-bottom: 0px; color: light-dark(var(--text-primary-color), #fff); }
.action-header { border-bottom: 1px solid #E3E3E3; }
.action-item { display: flex; justify-content: space-between; align-items: center; }
.action-item button { border: 1px solid var(--theme-secondary); display: flex; justify-content: space-between; align-items: center; text-align: center; padding: 18px 10px 18px 20px; color: var(--theme-secondary); background: #fff; border-radius: 6px; min-width: 194px; max-width: 194px; font-size: 16px; transition: all 0.1s ease-in-out; }
.action-item button:hover { background: var(--theme-secondary); color: #fff; }
.action-item button img { margin-left: 5px; }
.action-item button:hover img { filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(360deg) brightness(1.2) contrast(1); }
.action-item button img.red-heart{
    filter: invert(14%) sepia(94%) saturate(2715%) hue-rotate(343deg) brightness(100%) contrast(94%);
}
.action-item:not(:last-child) { padding-bottom: 25px; border-bottom: 1px solid #E3E3E3; }
.action-item:not(:first-child) { padding-top: 25px; }
.action-item span { font-size: 22px; font-weight: 500; }
.action-item .btn { font-size: 14px; padding: 6px 12px; border-radius: 5px; }
.dark-mode .twitter-icon { filter: invert(1); }
.feedback-question { font-size: 16px; margin: 34px 0 18px; }
.tell-us-form { background: #FAFAFA; margin-top: 15px; }
.tell-us-form textarea { background: #fff; border: 1px solid #D5D5D5; max-width: 463px; width: 100%; height: 83px; padding: 10px; border-radius: 6px; }
.back-submit-btns a { color: var(--theme-secondary); text-decoration: none; font-size: 16px; font-weight: 500; }
.back-submit-btns span { background: light-dark(#E3E3E3, #000000); border-radius: 50%; font-size: 13px; display: flex; height: 24px; width: 24px; justify-content: center; align-items: center; cursor: pointer; }
textarea:focus-visible { outline: none; }
.tell-us-form label { color: #343434; font-size: 16px; margin-bottom: 13px; }
.form-content-wrapper { max-width: 459px; margin: 0 auto; padding: 20px 10px; }
.feedback-emojis { display: flex; justify-content: space-between; align-items: center; max-width: 400px; margin: 0 auto; }
.feedback-emojis .emoji { transition: transform 0.2s; }
.feedback-emojis .emoji:hover { transform: scale(1.2); }
.share-label { font-size: 14px; margin-right: 10px; }
@media (max-width: 768px) { .action-item { flex-direction: column; align-items: flex-start; } .action-item .btn { margin-top: 10px; width: 100%; } .feedback-emojis { justify-content: space-around; } .action-item { padding-bottom: 25px; text-align: center; justify-content: center; align-items: center; gap: 12px; } .action-item span { font-size: 20px; } .action-item button { padding: 12px 10px 12px 15px; } }
.take-action-section { font-family: Arial, sans-serif; padding: 20px 0; }
.action-buttons .btn { font-size: 14px; padding: 8px 12px; display: inline-flex; align-items: center; justify-content: space-between; border-radius: 5px; }
.action-buttons .btn i { margin-left: 8px; }
.share-icons i { font-size: 1.5rem; color: #333; cursor: pointer; transition: color 0.3s ease-in-out; }
.share-icons i:hover { color: #007bff; }
.share-wrapper{padding: 10px 10px 10px 0;}
@media (max-width: 768px) { .section-title { text-align: center; } .action-buttons { text-align: center; } }

