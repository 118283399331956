.membership-sec {
  background-image: url("../../../assets/images/legal-advice-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.membership-sec .content-wrapper {
  padding: 120px 0 100px;
}
.membership-sec .title {
  font-size: 100px;
  line-height: 1.3;
  margin-top: -20px;
}
.membership-sec .member-img {
  position: absolute;
  width: calc(100% + 50px);
  top: -100px;
  bottom: 0;
  left: -50px;
}
@media (max-width: 1600px) {
  .membership-sec .content-wrapper {
    padding: 100px 0 80px;
  }
  .membership-sec .title {
    font-size: 80px;
  }
}
@media (max-width: 1299px) {
  .membership-sec .content-wrapper {
    padding: 80px 0 60px;
  }
  .membership-sec .title {
    font-size: 65px;
  }
}
@media (max-width: 991px) {
  .membership-sec .member-img {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    top: -50px;
    left: auto;
  }
  .membership-sec .content-wrapper {
    padding: 0 0 60px;
  }
  .membership-sec .title {
    font-size: 50px;
    margin-top: -10px;
  }
}
@media (max-width: 575px) {
  .membership-sec .title {
    font-size: 40px;
  }
}
