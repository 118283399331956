.central-building-sec {
  padding: 110px 0 90px;
  background: linear-gradient(180deg, #0625439d, #01070dc6);
}
.central-building-sec::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("../../../assets/images/role-of-center-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1399px) {
  .central-building-sec {
    padding: 80px 0 70px;
  }
}
@media (max-width: 991px) {
  .central-building-sec {
    padding: 60px 0 50px;
  }
}
