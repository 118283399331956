.footer::before {content: "";position: absolute;z-index: -1;top: 0;left: 0;width: 100%;height: 100%;background-image: url("../../../assets/images/footer-bg.png");background-size: cover;background-repeat: no-repeat;}
.footer .top-sec {padding: 80px 0 100px;background: transparent linear-gradient(180deg, #062543 0%, #01070dc6 100%);}
.footer .title {margin-bottom: 30px;}
.footer .footer-links li a,
.footer .contact-info li a {color: #fff;transition: all ease-in-out 0.3s;}
.footer .footer-links li a:hover,
.footer .contact-info li a:hover {color: #ed9c2e;}
.footer .contact-info li a svg{font-size: calc(var(--font-size-regular) + 4px);}
.footer .content-wrapper {padding-top: 40px;}
.footer .bottom-sec {padding: 14px 0;background-color: #000000d5;}
.footer .bottom-icons-list li{border: 1px solid #AEAEAE;border-radius: 50%;width: 46px;height: 46px;font-size: calc(var(--font-size-regular) + 2px);line-height: 25px;transition: all ease-in-out 0.3s;}
.footer .bottom-icons-list li:hover{background-color: #D5D5D5;color: #1A65AD;}
.footer .bottom-icons-list li svg{font-size: calc(var(--font-size-regular) + 2px);max-width: 22px;fill: #fff;stroke: #fff;}
.footer .bottom-icons-list li:hover svg{fill: #1A65AD;stroke: #1A65AD;}
.footer .bottom-icons-list li .moon-svg{font-size: calc(var(--font-size-regular) + 6px);transform: rotate(335deg);}
.light-mode .footer .sun-svg, .dark-mode .footer .moon-svg{display: block;}
.dark-mode .footer .sun-svg, .light-mode .footer .moon-svg{display: none;}
.footer .eye-open{display: none;}
.colorless-mode .footer .eye-slash{display: none;}
.colorless-mode .footer .eye-open{display: block;}
.newsletter-form .input-text {background-color: #f8f8f8; padding: 15px 20px; display: inline-block; border: none; outline: none; width: 100%;border-radius: 4px 0 0 4px;}
.newsletter-form .newsletter-submit{border-radius: 0 4px 4px 0;}
/* .newsletter-form .newsletter-element .newsletter-submit:hover {
  background-color: #666565;
} */
@media (max-width: 1399px) {
  .footer .top-sec {padding: 70px 0 80px;}
  .social-icons li a, .footer .bottom-icons-list li{width: 40px;height: 40px;}
}
@media (max-width: 991px) {
  .footer .top-sec {padding: 60px 0;}
  .footer .title {margin-bottom: 20px;}
  .footer .content-wrapper {padding-top: 30px;}
}
@media (max-width: 575px) {
  .footer .top-sec {padding: 50px 0;}
  .footer .content-wrapper {padding-top: 20px;}
  .footer .title {margin-bottom: 15px;}
  .social-icons li a, .footer .bottom-icons-list li{width: 35px;height: 35px;}
  .social-icons li a svg{font-size: 16px;}
  .footer .bottom-icons-list li svg{max-width: 16px;}
}
