.common-banner {
  height: 65vh;
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.common-banner p {
  font-size: var(--font-size-large);
  line-height: 32px;
}
@media (max-width: 1600px) {
  .common-banner p {
    font-size: 20px;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .common-banner {
    background-size: cover;
    background-position: center;
    text-align: center;
  }
  .common-banner p {
    font-size: 18px;
    line-height: 28px;
  }
}
